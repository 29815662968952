import React from "react"
import { Col, Divider, List, Row, Typography } from "antd"
import PropTypes from "prop-types"
import Gallery from "../PhotoGallery/PhotoGallery"
import style from "./RoomType.module.scss"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {},
}

const imgColProps = {
  xs: 22,
  sm: 20,
  md: 18,
  lg: { span: 10, pull: 1 },
}

const specsColProps = {
  xs: 22,
  sm: 20,
  md: 18,
  lg: { span: 10, push: 0 },
  style: {},
}

const galleryProps = (urls, name) => {
  return {
    photos: urls,
    name: name,
    settings: {
      showThumbs: false,
    },
  }
}

const title = name => {
  const titleProps = {
    className: style.title,
    level: 2,
    style: {},
  }

  return <Typography.Title {...titleProps}>{name}</Typography.Title>
}

const roomSpecs = (beds, sqft, sleeps) => {
  const rowProps = {
    align: "middle",
    justify: "space-between",
    type: "flex",
    style: {
      background: `rgba(247, 247, 247, 1.0)`,
      border: `2px solid rgba(0, 0, 0, 0.03)`,
      borderRadius: 3,
      marginBottom: 24,
      textAlign: `center`,
    },
  }

  const colProps = {
    span: 6,
    style: {
      padding: `12px 4px`,
    },
  }

  const labelProps = {
    className: style.label,
    style: {
      color: `#263746`,
      fontWeight: 700,
      textTransform: `uppercase`,
    },
  }

  const specProps = {
    className: style.spec,
    style: {
      color: `#4296b4`,
      fontFamily: `Arvo`,
      fontSize: 18,
      fontWeight: 900,
      marginLeft: 4,
    },
  }

  return (
    <Row {...rowProps}>
      <Col {...colProps}>
        <span {...labelProps}>Beds</span>
        <span {...specProps}>{beds}</span>
      </Col>
      <Col {...colProps}>
        <span {...labelProps}>SqFt</span>
        <span {...specProps}>{sqft}</span>
      </Col>
      <Col {...colProps}>
        <span {...labelProps}>Sleeps</span>
        <span {...specProps}>{sleeps}</span>
      </Col>
    </Row>
  )
}

const desc = desc => {
  const descriptionListProps = {
    className: style.description,
    dataSource: desc.split("\n").filter(item => item.length),
    grid: {
      column: 2,
      gutter: 16,
    },
    renderItem: feat => (
      <List.Item className={style.roomFeature}>{feat.trim()}</List.Item>
    ),
  }

  return (
    <>
      <Typography.Text className={style.featuresTitle}>
        Features:
      </Typography.Text>
      <List {...descriptionListProps} />
    </>
  )
}

const dividerProps = {
  style: {
    marginTop: 64,
    marginBottom: 64,
  },
}

const callToReserve = roomType => {
  if (roomType.startsWith("2") || roomType.startsWith("3")) {
    return <div className={style.ctr}>Call To Reserve</div>
  } else return null
}

const RoomType = ({
  beds,
  description,
  floorplans,
  interiors,
  roomType,
  sleeps,
  squareFeet,
}) => {
  let photoUrls = []
  interiors !== null &&
    interiors.forEach(int => {
      photoUrls.push(int.localFile.childImageSharp.fluid)
    })
  floorplans !== null &&
    floorplans.forEach(flp => {
      photoUrls.push(flp.localFile.childImageSharp.fluid)
    })

  return (
    <Row id={roomType.replace(/ /g, "-")} {...rowProps}>
      <Col {...imgColProps}>
        {callToReserve(roomType)}
        <Gallery {...galleryProps(photoUrls, roomType)} />
      </Col>
      <Col {...specsColProps}>
        {title(roomType)}
        {roomSpecs(beds, squareFeet, sleeps)}
        {desc(description.description)}
      </Col>
      <Divider {...dividerProps} />
    </Row>
  )
}

RoomType.propTypes = {
  roomType: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  interiors: PropTypes.arrayOf(PropTypes.object).isRequired,
  beds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sleeps: PropTypes.number.isRequired,
  squareFeet: PropTypes.number.isRequired,
}

RoomType.defaultProps = {
  roomType: null,
  description: null,
  interiors: null,
  bedrooms: 1,
  sleeps: 2,
  sqft: null,
}

export default RoomType
