import React from "react"
import { Icon } from "antd"
import style from "./Arrows.module.scss"

const arrowSize = 32

export const nextArrow = (
  <div
    style={{
      position: `absolute`,
      zIndex: 9999,
    }}
  >
    <Icon
      type="arrow-right"
      className={style.arrow}
      style={{
        fontSize: arrowSize,
        right: 40,
        zIndex: 2,
        position: `relative`,
      }}
    />
    <Icon
      type="arrow-right"
      className={style.arrowShadow}
      style={{
        fontSize: arrowSize,
        right: 40,
        marginTop: arrowSize * -1,
        zIndex: 1,
        position: `relative`,
      }}
    />
  </div>
)

export const prevArrow = (
  <div
    style={{
      position: `absolute`,
      zIndex: 9999,
    }}
  >
    <Icon
      type="arrow-left"
      className={style.arrow}
      style={{
        fontSize: arrowSize,
        left: 30,
        zIndex: 2,
        position: `relative`,
      }}
    />
    <Icon
      type="arrow-left"
      className={style.arrowShadow}
      style={{
        fontSize: arrowSize,
        left: 30,
        marginTop: arrowSize * -1,
        zIndex: 1,
        position: `relative`,
      }}
    />
  </div>
)
