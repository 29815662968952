import React from "react"
import { Carousel, Tag } from "antd"
import Img from "gatsby-image"
import Lightbox from "../Lightbox/Lightbox"
import device from "../../utils/device"
import PropTypes from "prop-types"
import style from "./PhotoGallery.module.scss"

import { nextArrow, prevArrow } from "./Arrows"

const thumbsImgProps = {
  style: {
    cursor: `pointer`,
    height: `6vh`,
    maxHeight: 96,
    objectFit: `contain`,
    padding: device.ifMobile(2, 4),
    width: `100%`,
  },
}

class PhotoCarousel extends React.Component {
  static propTypes = {
    allowLightbox: PropTypes.bool,
  }

  static defaultProps = {
    allowLightbox: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentPhoto: null,
      nav1: null,
      nav2: null,
      alt: null,
      fluid: null,
      vis: false,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
    this.setPhoto(0)
  }

  setPhoto = num => {
    this.setState({ currentPhoto: num })
  }

  openLightbox = (alt, fluid) => {
    this.setState({
      alt: alt,
      fluid: fluid,
      vis: true,
    })
  }

  closeLightBox = () => {
    this.setState({ vis: false })
  }

  render() {
    const { settings } = this.props
    const carouselProps = {
      arrows: settings.arrows || true,
      draggable: true,
      dots: settings.dots || false,
      nextArrow: nextArrow,
      prevArrow: prevArrow,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: settings.speed || 400,
      style: {
        background: settings.background || `rgba(0,0,0,0.0)`,
      },
    }

    const thumbsProps = {
      draggable: true,
      dots: false,
      focusOnSelect: true,
      lazyLoad: true,
      numThumbs: settings.numThumbs || device.ifMobile(5, 9),
      speed: settings.speed || 400,
      style: {
        marginTop: 4,
        marginBottom: 0,
      },
      swipeToSlide: true,
    }

    const numTagBottomPos = settings.showThumbs
      ? `calc(${thumbsImgProps.style.height} + 16px)`
      : 10

    const tagProps = {
      color: `rgba(0,0,0,0.8)`,
      style: {
        bottom: numTagBottomPos,
        position: `absolute`,
        right: -6,
        zIndex: 9,
      },
    }

    return (
      <>
        {this.props.allowLightbox ? (
          <Lightbox
            currentPhoto={this.state.currentPhoto}
            photos={this.props.photos}
            onCancel={this.closeLightBox}
            vis={this.state.vis}
            parent={this.slider1}
          />
        ) : (
          <></>
        )}
        <Tag {...tagProps}>
          {`${this.state.currentPhoto + 1} of ${this.props.photos.length}`}
        </Tag>
        <Carousel
          {...carouselProps}
          afterChange={current => this.setPhoto(current)}
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          {this.props.photos.map(photo => {
            const name = this.props.name
            return (
              <button
                className={style.imageWrapper}
                key={name}
                style={{ width: `100%` }}
                onClick={() => {
                  if (this.props.allowLightbox) {
                    this.openLightbox(name, photo)
                  }
                }}
              >
                <Img alt={name} fluid={photo} />
              </button>
            )
          })}
        </Carousel>
        {settings.showThumbs ? (
          <Carousel
            {...thumbsProps}
            asNavFor={this.state.nav1}
            centerMode={this.props.photos.length < 9 ? false : true}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={
              this.props.photos.length < thumbsProps.numThumbs
                ? this.props.photos.length
                : thumbsProps.numThumbs
            }
          >
            {this.props.photos.map((photo, index) => {
              return (
                <div key={`${photo}-thumb`} style={{ width: `100%` }}>
                  <Img
                    {...thumbsImgProps}
                    alt={`${this.props.name} Thumbnail`}
                    className={
                      this.state.currentPhoto === index
                        ? style.activeThumbnail
                        : style.thumbnail
                    }
                    fluid={photo}
                  />
                </div>
              )
            })}
          </Carousel>
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default PhotoCarousel
