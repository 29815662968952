import React from "react"
import { Affix, Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import RoomType from "../components/RoomType/RoomType"

const row = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: 64,
    maxWidth: 1360,
  },
}

const col = {
  span: 24,
}

const listRooms = rooms => {
  return rooms.map((room, index) => <RoomType key={index} {...room} />)
}

export default ({ data }) => {
  const rooms = data.ctf.nodes
  const { hero } = data

  const jsonld = rooms.map(room => ({
    "@context": "http://schema.org",
    "@type": "HotelRoom",
    name: room.roomType,
    description: room.description.description,
    image: room.floorplans[0].localFile.childImageSharp.fluid.src,
    petsAllowed: "http://schema.org/True",
    smokingAllowed: "http://schema.org/False",
    url: `${process.env.SITE_URL}/rooms#${room.roomType.replace(/ /g, "-")}`,
    yearBuilt: "2019",
    accommodationFloorPlan: {
      "@type": "FloorPlan",
      numberOfBathroomsTotal: 1,
      numberOfRooms: room.roomType.includes("Suite") ? 2 : 1,
    },
    bed: {
      "@type": "BedDetails",
      numberOfBeds: room.roomType.includes("Queen") ? 2 : 1,
      typeOfBed: room.roomType.includes("Queen") ? "Queen" : "King",
    },
    floorSize: {
      "@type": "QuantitativeValue",
      value: room.squareFeet,
      unitCode: "FTK",
    },

    occupancy: {
      "@type": "QuantitativeValue",
      maxValue: room.sleeps.toString(),
      unitCode: "C62",
    },
  }))

  const meta = {
    seo: {
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
      schema: jsonld,
      title: "Room & Suite Types | Lakeside Lodge Clemson",
      desc: `Rooms at Lakeside Lodge Clemson offer you the comforts of home and the \
        simplicity of having everything you need all in one place.`,
    },
    uri: "/rooms",
  }

  const heroProps = {
    caption: (
      <Typography.Title
        style={{
          fontFamily: `inherit`,
          fontSize: 36,
          textTransform: `uppercase`,
        }}
      >
        <span style={{ color: `#fff` }}>
          More than a room. A better home away from home.
        </span>
      </Typography.Title>
    ),
    contentfulAsset: hero,
    imageStyle: { filter: `brightness(0.75)` },
  }

  const handleAffixChange = (affixed, elemID) => {
    if (typeof document !== "undefined") {
      const bar = document.getElementById(elemID)
      if (affixed) {
        bar.classList.add("add-box-shadow")
      } else {
        bar.classList.remove("add-box-shadow")
      }
    }
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero {...heroProps} />
      <Affix
        offsetTop={0}
        onChange={affix => handleAffixChange(affix, "booking-bar")}
      >
        <BookingBar showCTAButtons={false} />
      </Affix>
      <Row {...row}>
        <Col {...col}>{listRooms(rooms)}</Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    ctf: allContentfulRoom(
      filter: { hotel: { name: { eq: "Lakeside Lodge Clemson" } } }
      sort: { fields: squareFeet, order: ASC }
    ) {
      nodes {
        beds
        description {
          description
        }
        interiors {
          description
          localFile {
            childImageSharp {
              fluid(jpegProgressive: true, jpegQuality: 50, webpQuality: 50) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
              }
            }
          }
        }
        floorplans {
          localFile {
            childImageSharp {
              fluid(jpegProgressive: true, jpegQuality: 50, webpQuality: 50) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
              }
            }
          }
        }
        roomType
        sleeps
        squareFeet
        viewType
      }
    }
    hero: contentfulAsset(contentful_id: { eq: "7yPznJc1tBoPhcuUlWp9yk" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  }
`
