import React from "react"
import { Carousel, Icon, Modal, Tag } from "antd"
import Img from "gatsby-image"
import style from "./Lightbox.module.scss"

import { nextArrow, prevArrow } from "../PhotoGallery/Arrows"

class Lightbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPhoto: 0,
    }
  }

  render() {
    const {
      closeIconColor,
      currentPhoto,
      onCancel,
      parent,
      photos,
      vis,
    } = this.props

    return (
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        centered={true}
        className={style.modal}
        closeIcon={
          <Icon
            style={{ color: closeIconColor || `rgba(0,0,0,0.5)` }}
            type="close"
          />
        }
        destroyOnClose
        footer={null}
        onCancel={onCancel}
        visible={vis}
        width="90vw"
      >
        {photos.length > 1 ? (
          <Tag className={style.tag} color="rgba(0,0,0,0.8)">
            {`${this.state.currentPhoto + 1} of ${photos.length}`}
          </Tag>
        ) : null}
        <Carousel
          afterChange={current => this.setState({ currentPhoto: current })}
          arrows={() => photos.length > 1}
          dots={false}
          draggable={true}
          initialSlide={currentPhoto}
          nextArrow={nextArrow}
          prevArrow={prevArrow}
          swipeToSlide
          asNavFor={parent || null}
        >
          {photos.map((photo, index) => (
            <div className={style.imageWrapper} key={index}>
              <Img alt={photo.src} className={style.image} fluid={photo} />
            </div>
          ))}
        </Carousel>
      </Modal>
    )
  }
}

export default Lightbox
